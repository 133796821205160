* {
    /*border: 1px solid red;*/
}

.App {
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color: #000000a0;
    /*position: relative;*/
    /*width: 600pt;*/
    margin: auto;
    /*border: 1px solid orange;*/
    height: 100vh;
    min-height: 300pt;
    min-width: 600pt;
    /*min-height: 600pt;*/
    overflow-y: hidden;
    overflow-x: auto;
}

.NotAdmin {
    visibility: hidden;
}

.Admin {
    visibility: visible;
}

#header {
    width: 190pt;
    height: 110pt;
    position: fixed;
    left: 20pt;
    top: 10pt;
    z-index: 100;
}

#headerImg {
    width: 100%;
    height: 100%;
}

#menu {
    position: absolute;
    top: 60pt;
    right: 20pt;
    text-align: right;
    display: inline-block;
    z-index: 100;
}

#infoButton {
    position: fixed;
    top: 15pt;
    right: 20pt;
    font-size: 12pt;
    padding: 6pt 25pt;
    border-radius: 15pt;
    outline: gray;
}

#infoPanel {
    position: absolute;
    height: 600pt;
    width: 420pt;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: ghostwhite;
    z-index: 50;
    border-radius: 10pt;
    overflow: hidden;
    /*border: 1px solid lightgreen;*/
    display: flex;
}

Button {
    font: 10pt 'Arial Black';
    border-radius: 8pt;
    outline-width: 0;
    margin: 8pt;
}

#body {
    /*background-color: red;*/
    /*position: fixed;*/
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100%;
    /*position: center;*/
}

.Centered {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.ColumnBack {
    position: absolute;
    border-radius: 8pt;
    left: -0pt;
    width: 100%;
    height: 100vh;
    top: 0;
    /*margin: -8pt;*/
    /*bottom: 0;*/
    z-index: -10000000000000000;
}


#scoreboard {
    -webkit-transform: translate3d(0,0,0);
    /*border: 1px solid red;*/
    padding: 0 8pt 0;
    border-bottom: 1px solid lightgrey;
    height: 75pt;
    width: 600pt;
    margin: 45pt auto 0;
    /*overflow-x: hidden;*/
    /*overflow-y: visible;*/
    display: flex;
    flex: 0 0 75pt;
    justify-content: flex-end;
    /*flex-direction: row-reverse;*/
    text-align: center;
    /*box-shadow: 0 8pt 14pt -10pt dimgrey;*/
}

#resultDiv{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    /*border: 1px solid black;*/
}

.VoteOption {
    cursor: pointer;
    width: 100pt;
    margin: 4pt;
}

.BoxContainer {
    position: relative;
    width: 95pt;
    /*height: 100pt;*/
    margin: 2pt 6pt;
    padding: 4pt 8pt;
    text-align: center;
    justify-content: center;
    overflow: visible;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /*flex-direction: column;*/
    /*border: 1px solid orange;*/
}

.ScoreDisplay {
    font-size: 36pt;
    font-family: "Arial Black";
    width: 100%;
    /*border: 1px solid paleturquoise;*/
}

#epiContainer {
    overflow-y: scroll;
    width: auto;
    height: calc(100vh - 120pt);
    padding-bottom: 100px;
    /*border: 1px solid green*/
}

#episodes {
    flex: 1;
    width: 600pt;
    margin: 0 auto;
    /*border: 1px solid blueviolet;*/
    overflow: visible;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.Episode {
    /*border: 1px solid cyan;*/
    margin-top: 6pt;
    padding: 0 8pt;
    position: relative;
}

.EpisodeHeader {
    font-size: 15pt;
    padding: 8pt 8pt;
}

.EpisodeTitle {
}

.EpisodeBack {
    background-color: #e5e5e5;
    position: absolute;
    height: 100%;
    width: calc(100% - 16pt);
    z-index: -2;
    border-radius: 8pt;
    /*border: 1px solid red;*/
}

.Vote {
    /*border: 1px solid blue;*/
    width: 100%;
    display: flex;
}

.VoteDescription {
    position: relative;
    /*display: flex;*/
    flex: 1;
    font-size: 10pt;
    /*padding: 0 8pt;*/
    /*padding-left: 12pt;*/
    /*padding-right: ;*/
    padding: 0 22pt 0 14pt;
    margin: auto 0 auto 0;
    text-align: left;
    /*border: 1px solid palegreen;*/
}

.PointValueTag {
    color: ghostwhite;
    font-size: 12pt;
    font-family: "Arial Black";
    position: absolute;
    text-shadow: 0 0 2pt grey;
    width: 18pt;
    height: 18pt;
    line-height: 16pt;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 10pt;
    border: 1px solid lightslategray;
    /*background-color: whitesmoke;*/
    text-align: center;
    /*text*/
    background: repeating-linear-gradient(
            136deg,
            white,
            lightslategray 16px,
            lightslategray 30pt
    );
}

.PlaceTag {
    width: 25pt;
    height: 25pt;
    position: absolute;
    left: -4pt;
    top: -16%;
    /*clip-path: circle(49% at 50% 50%);*/
    font-size: 16pt;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #00000030;
    border-radius: 50%;
    box-shadow: 1px 1px 3px #00000040;
}

.VoteButton {
    cursor: pointer;
    position: relative;
    padding: 6pt 12pt;
    text-align: center;
}

.VotePanel {
    background-color: ghostwhite;
    position: absolute;
    border-radius: 10pt;
    padding: 10pt;
    left: 50%;
    top: 10%;
    width: 80%;
    transform: translate(-50%, 0%);
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0 0 20pt -4pt dimgray;
    z-index: 1000;
}

.VoteGroupOptionContainer {
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: center;
}
.VoteTag {
    width: 16pt;
    position: absolute;
    right: 6%;
    top: 2%;
}

.BallotOption {
    position:relative;
    width: 120pt;
    height: 120pt;
}

.Portrait {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 4pt;
    /*border: 1px solid red;*/
}


.CastImgContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 6pt;
    margin-bottom: 2px;
    /*border: 1px solid yellowgreen;*/
}

.CastImgContainer:after {
  content: "";
  display: block;
  padding-bottom: 100%;
    position:relative;
}

.CastImg {
    position: absolute;
    width: 140%;
    /*left: 40%;*/
    height: auto;
    transform: translate(-50%, -10pt);
}

.NameTag {
    font: 11pt 'Helvetica Neue';
    text-align: center;
    color: white;
    /*position: absolute;*/
    bottom: 0;
    left: 0;
    /*padding: 1pt 8pt;*/
    border-radius: 5px;

    white-space: nowrap;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
}

.PortraitLabel {
    position: relative;
    font: 22pt solid Helvetica Neueu;
    font-family: Impact, sans-serif;
    font-variant: small-caps;
    padding: 8pt 4pt;
    border-radius: 6pt;
}

.TribalExpansionContainer {
    -webkit-transform: translate3d(0,0,0);
    /*display: flex;*/
    position: absolute;
    top: 130%;
    left: 50%;
    transform: translateX(-50%);
    width: 250pt;
    max-height: 300pt;
    /*border: 1px solid yellowgreen;*/
    box-shadow: 0 0 10pt grey;
    border-radius: 20pt;
    z-index: 100000000;
}

.TribalExpansion {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 6pt;
    /*border: 1px solid dodgerblue;*/
    z-index: 20;
    border-radius: 20pt;
}

.SmallVoteOption {
    width: 75pt;
    margin: 2pt;
}

.DownPointContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10pt;
}

.DownPoint {
    width: 20pt;
    height: 20pt;
    position:absolute;
    left: 50%;
    top: -8pt;
    transform: translateX(-50%) rotateZ(45deg);
    border-radius: 2pt;
    z-index: -100;
}
